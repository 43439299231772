:root {
  --light: #f6f6f9;
  --primary: #19d257;
  --light-primary: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #363949;
  --danger: #D32F2F;
  --light-danger: #FECDD3;
  --warning: #FBC02D;
  --light-warning: #FFF2C6;
  --success: #388E3C;
  --light-success: #BBF7D0;
  --scroll-background-light: #bdbdbd90;
  --scroll-background-dark: ;
  --scroll-roll-light: #ffffffd1;
  --scroll-roll-hover-light: #dfdfdf;
}

.page_404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
  font-family: 'Arvo', serif;
}

.page_404 .container {
  text-align: center;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/userupload/8726278/file/original-ab1bde6f9c74de5c8961f7fe84990cd4.gif);
  height: 600px;
  width: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
  color: var(--dark);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50px;
  width: 100%;
  text-align: center;
}

.contant_box_404 {
  margin-top: -50px;
}

.contant_box_404 h3 {
  font-size: 2em;
  color: var(--dark);
}

.contant_box_404 p {
  font-size: 1.2em;
  color: var(--dark-grey);
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: var(--primary);
  margin: 20px 0;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s, color 0.3s;
}

.link_404:hover {
  background: var(--success);
}