:root {
  --light: #f6f6f9;
  --tester: #a39e00;
  --yellow: #ff9860;
  --light-primary: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #363949;
  --danger: #D32F2F;
  --light-danger: #FECDD3;
  --mod: #4a6cff;
  --light-warning: #FFF2C6;
  --client: #388E3C;
  --light-success: #BBF7D0;
  --scroll-background-light: #bdbdbd90;
  --scroll-background-dark: ;
  --scroll-roll-light: #ffffffd1;
  --scroll-roll-hover-light: #dfdfdf;
  --white: #ffffff;
  --background-filter: #dfdfdf;
  --darkmode-component: #181A1E;
  --darkmod-selectable-component: #25252C;
  --opacity-simulation: #8e8e8e;
  --upload-color: #59afff;
  --green-theme: #19D257;
}

.content main .users-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}

.content main .left-user-page {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  height: auto;
  width: 45%;
  color: var(--dark);
}

.content main .left-user-page .users {
  position: relative;
  padding: 20px 20px 0px 20px;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  height: 300px;
  overflow-y: auto;
}

/* DarkMode */
body.dark .content main .left-user-page .users {
  background-color: var(--darkmode-component);
}
/* DarkMode */

.content main .header h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}

.content main .left-user-page .users table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.content main .left-user-page .users table th {
  padding-bottom: 12px;
  font-size: 13px;
  text-align: left;
  border-bottom: 1px solid var(--grey);
}

.content main .left-user-page .users table td {
  padding: 16px 0;
}

.content main .left-user-page .users table tr td:first-child {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  padding-left: 6px;
}

.content main .left-user-page .users table td img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.content main .left-user-page .users table tbody tr {
  cursor: pointer;
  transition: all 0.3s ease;
}

.content main .left-user-page .users table tbody tr:hover {
  background: var(--light);
}

/* DarkMode */
body.dark .content main .left-user-page .users table tbody tr:hover {
  background: var(--darkmod-selectable-component);
}
body.dark .content main .left-user-page .whappers-list ul li .info-whapper-connection {
  color: black
}

/* DarkMode */

.content main .left-user-page .users table tr td .status {
  font-size: 10px;
  padding: 6px 16px;
  color: var(--light);
  border-radius: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

.content main .left-user-page .users table tr td .status.client {
  background: var(--client);
}

.content main .left-user-page .users table tr td .status.tester {
  background: var(--tester);
}

.content main .left-user-page .users table tr td .status.mod {
  background: var(--mod);
}

.content main .left-user-page .users::-webkit-scrollbar {
  width: 10px;
}

.content main .left-user-page .users::-webkit-scrollbar-track {
  background: var(--scroll-background-light);
  border-radius: 10px;
}

.content main .left-user-page .users::-webkit-scrollbar-thumb {
  background: var(--scroll-roll-light);
  border-radius: 10px;
}

.content main .left-user-page .users::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-roll-hover-light);
}

.content main .left-user-page .users table {
  width: 100%;
  border-collapse: collapse;
}

.content main .left-user-page .whappers {
  position: relative;
  align-items: left;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  height: 350px;
  overflow-y: auto;
}

.padding {
  padding: 20px 20px 0px 20px;
}

.middleInfo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--opacity-simulation);
}

/* DarkMode */
body.dark .content main .left-user-page .whappers {
  background-color: var(--darkmode-component);
}
/* DarkMode */

.content main .right-user-page {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  height: auto;
  width: 53%;
  color: var(--dark);
}

.content main .right-user-page .user-info-data {
  position: relative;
  background-color: white;
  border-radius: 20px;
  width: 100%;
  height: 675px;
  overflow-y: auto;
}

/* DarkMode */
body.dark .content main .right-user-page .user-info-data {
  background-color: var(--darkmode-component);
}
/* DarkMode */

.content main .right-user-page .user-info-data .profile-picture {
  position: relative;
}

.content main .right-user-page .user-info-data .profile-picture .pictures {
  align-items: center;
  display: flex;
}

.content main .right-user-page .user-info-data .profile-picture .profile-picture-info {
  display: flex;
  margin-top: 20px;
  font-size: 18px;
  align-items: center;
}

.content main .right-user-page .user-info-data .profile-picture .profile-picture-info i {
  margin-right: 10px;
}

.content main .right-user-page .user-info-data .profile-picture .picture {
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 100%;
  height: 120px;
  width: 120px;
}

.content main .right-user-page .user-info-data .profile-picture .picture-button {
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
  border: 2px solid var(--upload-color);
  color: var(--white);
  outline: none;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
}

#remove {
  background-color: var(--white);
  color: var(--upload-color);
  transition: 0.2s ease-in;
}

#remove:hover {
  background-color: var(--upload-color);
  color: var(--white);
  cursor: pointer;
}

#upload {
  background-color: var(--upload-color);
  transition: 0.2s ease-in;
}

#upload:hover {
  background-color: var(--white);
  color: var(--upload-color);
  cursor: pointer;
}

.content main .right-user-page .user-info-data .acess-info .container-profile-acess {
  display: flex;
}

.content main .right-user-page .user-info-data .acess-info {
  position: relative;
}

.content main .right-user-page .user-info-data .acess-info .profile-acess-info {
  margin-top: 20px;
  font-size: 18px;
  align-items: center;
}

.content main .right-user-page .user-info-data .acess-info .profile-acess-info i {
  padding-right: 10px;
  vertical-align: middle;
  margin: 0 auto;
}

.content main .right-user-page .user-info-data .acess-info .profile-acess {
  width: 355px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.content main .right-user-page .user-info-data .acess-info h4 {
  display: flex;
  text-align: center;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
}

.content main .right-user-page .user-info-data .acess-info h4 i {
  color: var(--dark);
  border-radius: 5px;
  padding: 2px;
  transition: 0.3s ease-in;
}

.content main .right-user-page .user-info-data .acess-info h4 i:hover {
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
/* DarkMode */
body.dark .content main .right-user-page .user-info-data .acess-info h4 i:hover {
  color: var(--darkmod-selectable-component);
  background-color: var(--white);
}
/* DarkMode */

.content main .right-user-page .user-info-data .acess-info h4 a {
  display: flex;
  color: var(--dark);
  border-radius: 5px 0px 0px 5px;
  padding: 0px 20px 0px 20px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: normal;
}

.content main .right-user-page .user-info-data .acess-info .profile-permissions {
  width: 355px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-status {
  padding: 0px 25px 0px 25px;
  color: var(--white);
  margin-left: 10px;
  border-radius: 10px;
  transition: 0.2s ease-in;
}

.content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-status:hover {
  cursor: pointer;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.3);
}

.content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-number-of-connections {
  width: 50px;
  margin-left: 14.5px;
  border-radius: 5px;
  border: none;
  outline: none;
  height: 30px;
}

/* DarkMode */
body.dark .content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-number-of-connections {
  color: white;
  background-color: var(--darkmode-component);
}

body.dark .content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-status:hover {
  cursor: pointer;
  box-shadow: 0 0 5px 1px rgb(255, 255, 255);
}

/* DarkMode */

.content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-number-of-connections::-webkit-scrollbar {
  width: 10px;
}

.content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-number-of-connections::-webkit-scrollbar-track {
  background: var(--white);
  border-radius: 10px;
}

.content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-number-of-connections::-webkit-scrollbar-thumb {
  background: var(--scroll-roll-light);
  border-radius: 10px;
}

.content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-number-of-connections::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-roll-hover-light);
}

/* DarkMode */
body.dark .content main .right-user-page .user-info-data .acess-info .profile-permissions .profile-number-of-connections::-webkit-scrollbar-track {
  background: var(--darkmode-component);
  border-radius: 10px;
}
/* DarkMode */

.content main .left-user-page .whappers-list {
  display: block;
  margin-top: 10px;
  position: relative;
}

.content main .left-user-page .whappers-list ul li {
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px;
}

.content main .left-user-page .whappers-list ul li img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.content main .left-user-page .whappers-list ul li .info-whapper-connection {
  padding-left: 10px;
  margin-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.content main .left-user-page .whappers-list ul li i {
  border-radius: 8px;
  padding: 3px;
  margin-left: 15px;
  transition: 0.3s ease-out;
}

.content main .left-user-page .whappers-list ul li .buttons-whapper-info {
  margin-left: auto;
}

.content main .left-user-page .whappers-list ul li i:hover {
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Adiciona uma sombra */
}

#blue:hover {
  background-color: var(--mod);
  color: var(--white);
}

#yellow:hover {
  background-color: var(--yellow);
  color: var(--white);
}

#red:hover {
  background-color: var(--danger);
}

#grey:hover {
  background-color: var(--opacity-simulation);
}

.content main .left-user-page .whappers-list ul li .whapper-name-bot  {
  width: 150px;
}



@media screen and (max-width: 1550px) {
  .content main .left-user-page {
    width: 50%;
  }  
  .content main .right-user-page {
    width: 47%;
  }
  .content main .right-user-page .user-info-data .acess-info .container-profile-acess {
    display: block;
  }
}

@media screen and (max-width: 1310px) {
  .content main .left-user-page {
    width: 100%;
  }
  .content main .right-user-page {
    width: 100%;
  }
  .content main .right-user-page .user-info-data .acess-info .container-profile-acess {
    display: flex;
  }
}

@media screen and (max-width: 882px) {
  .content main .right-user-page .user-info-data .acess-info .container-profile-acess {
    display: block;
  }
}

.create-instance {
  display: flex;
  font-size: 15px;
  align-items: center;
  margin-bottom: 16px;
  background-color: #19D257;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.create-instance:hover {
  background-color: #078d00;
}