:root {
  --light: #f6f6f9;
  --primary: #19d257;
  --light-primary: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #363949;
  --danger: #D32F2F;
  --light-danger: #FECDD3;
  --warning: #FBC02D;
  --light-warning: #FFF2C6;
  --success: #388E3C;
  --light-success: #BBF7D0;
  --scroll-background-light: #bdbdbd90;
  --scroll-background-dark: ;
  --scroll-roll-light: #ffffffd1;
  --scroll-roll-hover-light: #dfdfdf;
}

.page_expired {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
  font-family: 'Arvo', serif;
}

.page_expired .container {
  text-align: center;
}

.expired_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 600px;
  width: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expired_bg h1 {
  font-size: 80px;
  color: var(--light);
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50px;
  width: 100%;
  text-align: center;
}

.content_box_expired {
  margin-top: -50px;
}

.content_box_expired h3 {
  font-size: 2em;
  color: var(--dark);
}

.content_box_expired p {
  font-size: 1.2em;
  color: var(--dark-grey);
}

.link_home {
  color: #fff !important;
  padding: 10px 20px;
  background: var(--primary);
  margin: 20px 0;
  display: inline-block;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s, color 0.3s;
}

.link_home:hover {
  background: var(--success);
}