:root {
  --light: #f6f6f9;
  --primary: #19d257;
  --light-primary: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #363949;
  --danger: #D32F2F;
  --light-danger: #FECDD3;
  --warning: #FBC02D;
  --light-warning: #FFF2C6;
  --success: #388E3C;
  --light-success: #BBF7D0;
  --scroll-background-light: #bdbdbd90;
  --scroll-background-dark: ;
  --scroll-roll-light: #ffffffd1;
  --scroll-roll-hover-light: #dfdfdf;
  --background-filter: #dfdfdf;
}

.origem-disparo {
  color: #181A1E;
}

.select-user {
  width: 300px;
  float: right;
}

@media screen and (max-width: 1426px) {
  .select-user {
    position: absolute;
    float: left;
  }
}

.info-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-in-out;
}

.info-modal-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: left;
  max-width: 500px;
  width: 90%;
}

.info-modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.info-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #D3D3D3 #F5F5F5;
}

.info-list li {
  margin-bottom: 10px;
  font-size: 16px;
}

.info-list li strong {
  color: #555;
}

.info-modal-buttons {
  display: flex;
  justify-content: center;
}

.close-button {
  background: #19D257;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.close-button:hover {
  background: #16a34a;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}