:root {
  --light: #f6f6f9;
  --tester: #a39e00;
  --yellow: #ff9860;
  --light-primary: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #363949;
  --danger: #D32F2F;
  --light-danger: #FECDD3;
  --mod: #4a6cff;
  --light-warning: #FFF2C6;
  --client: #388E3C;
  --light-success: #BBF7D0;
  --scroll-background-light: #bdbdbd90;
  --scroll-roll-light: #ffffffd1;
  --scroll-roll-hover-light: #dfdfdf;
  --white: #ffffff;
  --background-filter: #dfdfdf;
  --darkmode-component: #181A1E;
  --darkmod-selectable-component: #25252C;
  --opacity-simulation: #8e8e8e;
  --upload-color: #59afff;
  --green-theme: #19D257;
  --box-shadow: #0000001a;
  --clip: #CFE8FF;
  --clip-text: #007bff;
  --signal: #f3e8ff;
  --signal-text: #bb00ff;
}

h3 {
  font-size: 18px;
  color: #8E8E8E;
}

.instances {
  font-size: 23px;
}

h4 .bx-rocket {
  font-size: 25px;
  margin-right: 10px;  
  vertical-align: middle;
}

h4 .bxs-bookmark-alt {
  font-size: 25px;
    margin-right: 10px;
    vertical-align: middle;
}

h4 {
  font-size: 18px;
}

body.dark .content main .connection-user-page-left .left-box-my-instances {
  background-color: var(--darkmode-component);
}
body.dark .content main .connection-user-page-left .left-box-selected-whapper {
  background-color: var(--darkmode-component);
}
body.dark .content main .connection-user-page-left .left-box-billing {
  background-color: var(--darkmode-component);
}
body.dark .content main .connection-user-page-left .left-box-my-instances li {
  background-color: var(--darkmode-component);
}
body.dark .content main .connection-user-page-left .left-box-my-instances li:hover {
  background-color: var(--darkmod-selectable-component);
}
body.dark .content main .connection-user-page-right {
  background-color: var(--darkmode-component);
}
body.dark .content main .connections-data .connection-user-page-right-no-selected {
  background-color: var(--darkmode-component);
}

.content main .connection-user-page-left .left-box-my-instances::-webkit-scrollbar {
  width: 10px;
}

.content main .connection-user-page-left .left-box-my-instances::-webkit-scrollbar-track {
  background: var(--scroll-background-light);
  border-radius: 10px;
}

.content main .connection-user-page-left .left-box-my-instances::-webkit-scrollbar-thumb {
  background: var(--scroll-roll-light);
  border-radius: 10px;
}

.content main .connection-user-page-left .left-box-my-instances::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-roll-hover-light);
}

.content main .connections-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}

.content main .connection-user-page-left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 30%;
  height: 650px;
}

.left-box-billing,
.left-box-selected-whapper {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 2px 4px var(--box-shadow);
  display: flex;
  align-items: center;
}


.left-box-billing .boxClip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: var(--clip);
  /* Cor de fundo azul clara */
  border-radius: 10px;
  /* Bordas arredondadas */
  margin-right: 16px;
}

.left-box-billing .boxClip i {
  font-size: 24px;
  /* Tamanho do ícone */
  color: var(--clip-text);
  /* Cor azul do ícone */
}

.left-box-selected-whapper .signal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: var(--signal);
  /* Cor de fundo azul clara */
  border-radius: 10px;
  /* Bordas arredondadas */
  margin-right: 16px;
}

.left-box-selected-whapper .signal i {
  font-size: 24px;
  /* Tamanho do ícone */
  color: var(--signal-text);
  /* Cor azul do ícone */
}

.left-box-my-instances {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow-y: auto;
}

.left-box-my-instances h4 {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.left-box-my-instances .new-instance {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background-color: #19D257;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: bold;
  margin-left: auto;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.left-box-my-instances .new-instance:hover {
  background-color: #078d00;
}

.left-box-my-instances ul {
  list-style-type: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.left-box-my-instances ul li {
  list-style-type: none;
  
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  background-color: #F1F3F4;
  margin-bottom: 8px;
}

.left-box-my-instances ul li span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.red {
  color: red;
}
.green {
  color: green;
}
.yellow {
  color: yellow;
}

.left-box-my-instances ul li span.red {
  background-color: red;
}

.left-box-my-instances ul li span.yellow {
  background-color: yellow;
}

.left-box-my-instances ul li span.green {
  background-color: green;
}

.left-box-my-instances ul li:hover {
  background-color: #E0E0E0;
  cursor: pointer;
}

.content main .connections-data .connection-user-page-right {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 895;
  width: 68%;
  overflow-y: auto;
}

.content main .connections-data .connection-user-page-right-no-selected {
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-width: 895;
  width: 68%;
  overflow-y: auto;
}

@media (max-width: 1476px) {
  .content main .connections-data {
    display: block;
  }

  .content main .connections-data .connection-user-page-right {
    margin-top: 20px;
    width: 100%;
  }

  .content main .connections-data .connection-user-page-left {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    min-width: 350px;
    height: 650px;
  }
}

.actions,
.qr-code {
  display: inline-block;
  vertical-align: top;
}

.actions {
  width: 40%;
  padding: 10px;
}

.actions button,
.inputs button {
  background-color: var(--green-theme);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  width: 100%;
  margin-bottom: 10px;
}

.actions button:hover {
  background-color: #078d00;
}

.actions button i,
.inputs button i {
  margin-right: 5px;
}

.inputs input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.qr-code {
  width: 55%;
  padding: 10px;
  text-align: center;
}

.qr-code img {
  width: 70%;
  border: 10px solid var(--green-theme);
  border-radius: 5px;
}

.qr-code p {
  margin: 0 0 10px 0;
  font-weight: bold;
}

/* Media Query para telas menores */
@media (max-width: 600px) {

  .actions,
  .qr-code {
    display: block;
    width: 100%;
    text-align: center;
  }

  .qr-code img {
    width: auto;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 500px;
  width: 90%;
  
}

.modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;

}

.modal-content input {
  margin-bottom: 20px;
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.modal-content input:focus {
  border-color: #19D257;
  outline: none;
}

.modal-content button {
  padding: 12px 20px;
  margin: 5px;
  background: #19D257;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.modal-content button:hover {
  background: #19D257;
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

.new-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: newFadeIn 0.3s ease-in-out;
}

.new-modal-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.new-modal-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.option-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  max-height: 200px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #D3D3D3 #F5F5F5;
}

.option-item {
  margin-bottom: 10px;
}

.option-item input {
  margin-right: 10px;
}

.new-modal-buttons {
  display: flex;
  justify-content: space-between;
}

.confirm-button,
.cancel-button,
.redundancy-button {
  padding: 12px 20px;
  margin: 5px;
  background: #19D257;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.confirm-button:hover,
.cancel-button:hover,
.redundancy-button:hover {
  background-color: #16a34a;
}

.redundancy-button {
  background-color: #e67e22;
  /* Cor diferente para o botão de remover redundância */
}

.redundancy-button:hover {
  background-color: #d35400;
}

.new-modal-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

/* Custom scrollbar styles */
.option-list::-webkit-scrollbar {
  width: 12px;
  /* Largura da barra de rolagem */
}

.option-list::-webkit-scrollbar-thumb {
  background: #D3D3D3;
  /* Cor do "polegar" da barra de rolagem */
  border-radius: 6px;
  /* Bordas arredondadas do "polegar" */
  border: 3px solid #F5F5F5;
  /* Espaço ao redor do "polegar" */
}

.option-list::-webkit-scrollbar-track {
  background: #F5F5F5;
  /* Cor do "trilho" da barra de rolagem */
}

/* Animações */
@keyframes newFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}