:root {
  --light: #f6f6f9;
  --tester: #a39e00;
  --yellow: #ff9860;
  --light-primary: #CFE8FF;
  --grey: #eee;
  --dark-grey: #AAAAAA;
  --dark: #363949;
  --danger: #D32F2F;
  --light-danger: #FECDD3;
  --mod: #4a6cff;
  --light-warning: #FFF2C6;
  --client: #388E3C;
  --light-success: #BBF7D0;
  --scroll-background-light: #bdbdbd90;
  --scroll-background-dark: #2E2E2E;
  --scroll-roll-light: #ffffffd1;
  --scroll-roll-hover-light: #dfdfdf;
  --scroll-roll-dark: #606060;
  --scroll-roll-hover-dark: #505050;
  --white: #ffffff;
  --background-filter: #dfdfdf;
  --darkmode-component: #181A1E;
  --darkmod-selectable-component: #25252C;
  --opacity-simulation: #8e8e8e;
  --upload-color: #59afff;
  --green-theme: #19D257;
}

.service-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.service-list {
  display: flex;
  background-color: var(--light);
  overflow-x: auto;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
}

body.dark .service-list {
  background-color: var(--darkmode-component);
}

.service-item {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  padding: 20px;
  width: 225px;
  min-width: 225px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--grey);
  transition: background-color 0.3s;
}

body.dark .service-item {
  background-color: var(--darkmode-component);
}

.service-item:hover {
  background-color: #e6e6e6;
}

body.dark .service-item:hover {
  background-color: var(--darkmod-selectable-component);
}

.service-item.selected {
  background-color: #d4edda;
}

body.dark .service-item.selected {
  background-color: var(--darkmod-selectable-component);
}

.service-icon {
  margin-right: 10px;
  font-size: 25px;
}

body.dark .service-icon {
  color: white;
}

.service-name {
  margin-right: 5px;
}

body.dark .service-name {
  color: white;
}

.lock-icon {
  color: black;
  margin-left: auto;
}

body.dark .lock-icon {
  color: white;
}

.editor {
  padding: 20px;
  margin-top: 15px;
  border-radius: 10px;
  width: 100%;
  min-height: 620px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  text-align: center;
}

body.dark .editor {
  background-color: var(--darkmode-component);
}

.select-service-text {
  font-weight: bold;
  font-size: 30px;
  color: var(--opacity-simulation);
}

/* Scrollbar styles */
.service-list::-webkit-scrollbar {
  height: 8px;
}

.service-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

body.dark .service-list::-webkit-scrollbar-track {
  background: var(--scroll-background-dark);
}

.service-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

body.dark .service-list::-webkit-scrollbar-thumb {
  background: var(--scroll-roll-dark);
}

.service-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body.dark .service-list::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-roll-hover-dark);
}

.service-list::-webkit-scrollbar-button {
  display: none;
}

.command-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

body.dark .command-input input {
  background-color: var(--darkmode-component);
  color: white;
  border: 1px solid var(--dark-grey);
}

.command-input input {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

.command-input button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.command-input button:hover {
  background-color: #218838;
}

.command-list {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.command-list ul {
  list-style-type: none;
  padding: 0;
}

.command-list li {
  background-color: #e9ecef;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

body.dark .command-list li {
  background-color: var(--darkmod-selectable-component);
  color: white;
}

.command-list h3 {
  margin-bottom: 10px;
}

.commands-box {
  padding: 25px;
  margin-top: 15px;
  border-radius: 10px;
  width: 100%;
  min-height: 620px;
  height: 100%;
  display: flex;
  background-color: #f9f9f9;
  justify-content: space-between;
  flex-wrap: wrap;

}

body.dark .commands-box {
  background-color: var(--darkmode-component);
}

.header-select-instance {
  font-size: 25px;
  color: #363949;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

body.dark .header-select-instance {
  color: white;
}

.header-select-instance i {
  margin-right: 10px;
  font-size: 25px;
  color: #363949;
}

body.dark .header-select-instance i {
  color: white;
}

.input-section {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.select-instance {
  margin-bottom: 10px;
}

button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #19d2579d;
  color: white;
  cursor: pointer;
  transition: 0.2s ease-in;
}

button:hover {
  background-color: #19D257;
}

.addCommand,
.returnCommand {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 0.1px solid #ccc;
  outline: none;
  width: 100%;
}

.addCommand:focus,
.returnCommand:focus {
  border: 0.1px solid #19D257;
}

body.dark .addCommand,
body.dark .returnCommand {
  background-color: var(--darkmode-component);
  color: white;
  border: 0.1px solid var(--dark-grey);
}

.iconHover {
  padding: 8px;
  border-radius: 10px;
  margin-left: 10px;
  background-color: #19d2579d;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.iconHover:hover {
  background-color: #19D257;
  /* Cor ao passar o mouse */
}

.returnCommand {
  height: 75%;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}

.returnCommand::placeholder {
  position: relative;
  text-align: left;
}

.commands-list {
  width: 50%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}

body.dark .commands-list {
  background-color: var(--darkmode-component);
  color: white;
}

.select-command {
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.button-group .btn {
  padding: 10px 15px;
  margin-left: 20px;
  margin-right: 20px;
  width: 500px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.button-group .btn i {
  margin-right: 5px;
}

.button-group .btn.edit {
  background-color: #ffc107;
  color: white;
}

.button-group .btn.edit:hover {
  background-color: #e0a800;
}

.button-group .btn.delete {
  background-color: #dc3545;
  color: white;
}

.button-group .btn.delete:hover {
  background-color: #c82333;
}

.chat-box {
  flex: 1;
  background-image: url('https://i.pinimg.com/originals/5e/a6/aa/5ea6aadb19661e5843deb3abca4e17f9.jpg');
  background-size: cover;
  border-radius: 10px;
  max-height: 600px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: auto;
  overflow-x: hidden;
}

body.dark .chat-box {
  background-image: url('https://imagens.net.br/wp-content/uploads/2023/07/papel-de-parede-whatsapp-lindos-24.jpg');
}

.chat-bubble {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.chat-bubble.left {
  background-color: white;
  align-self: flex-start;
}

body.dark .chat-bubble.left {
  background-color: var(--darkmod-selectable-component);
  color: white;
}

.chat-bubble.right {
  background-color: #DCF8C6;
  align-self: flex-end;
}

body.dark .chat-bubble.right {
  background-color: var(--darkmod-selectable-component);
  color: white;
}

@media (max-width: 1080px) {
  .header-select-instance {
    margin-top: 25px;
  }

  .commands-box {
    flex-direction: column;
  }

  .input-section {
    width: 100%;
  }

  .commands-list {
    width: 100%;
    padding: 0;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .button-group .btn {
    width: 100%;
    margin: 5px 0;
  }
}

/*
forms
*/

.button-delete {
  background-color: #e25866;
  margin-top: 10px;
}

.button-delete:hover {
  background-color: #de172b;
}

/*
trigger
*/

.custom-input {
  border: 0.4px solid #dfdfdfcc;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  color: #333;
  outline: none;
  margin-bottom: 10px;
  transition: border-color 0.3s ease;
}

.custom-input:focus {
  border-color: #19D257;
  box-shadow: 0 0 2px #19D257;
}
